import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/bootstrap.css'
import './assets/css/bootstrap-theme.css'
import './assets/css/modal.css'
import './assets/css/main.css'
createApp(App).use(store).use(router).mount('#app')
