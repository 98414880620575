<template>
  <Transition name="fade">
    <div
      class="modal fade show"
      v-if="show"
      id="modalProduct"
      tabindex="-1"
      aria-labelledby="modalProductLabel"
      aria-hidden="true"
      @click.self="this.$parent.closeModals()"
    >
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/xUaB6fE6Xyo?si=-ob58rMbJHBgcE8G"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </Transition>
</template>
<script>
export default {
  name: "videoblock",
  props: ["show"],
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal iframe {
  width: 800px;
  height: 600px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: block;
  position: absolute;
}
</style>
