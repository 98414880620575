<template>
  <div v-if="$store.getters.getState">

    <section id="video-panel-block" class="video-panel-block closed">
      <div class="">
        <div class="row-no-gutters">
          <div class="col-lg-4">
            <div class="image">
              <img
              @click="popup()"
                   src="@/assets/images/video-panel.png"
                   alt="" />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content">
              <h2>Ireland's Premier Law Searches</h2>
              <p>Full Management of your Automated Fixed Fee Conveyancing Searches</p>
              <button @click="popup()">Watch Now</button>
              <div class="close-intro mobile">
                <a
                   href="javascript:void(0)"
                   title="close intro">close intro <span class="arrow thin white left"></span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-1">
            <div class="show-intro">
              <a
                 href="javascript:void(0)"
                 title="show intro">show intro <span class="arrow thin white right"></span></a>
            </div>
            <div class="close-intro">
              <a
                 href="javascript:void(0)"
                 title="close intro">close intro <span class="arrow thin white left"></span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <videoblock :show="showPopup"></videoblock>
  </div>
</template>

<script>
import videoblock from "@/components/modals/videoblock";
export default {
  name: 'VideoTmp',
  components: { videoblock },
  data() {
    return {
      showPopup: false
    }
  },
  methods: {
    closeModals() {
      this.showPopup = false;
    },
    popup() {
      this.showPopup = true;
      const videoPanelBlock = document.querySelector('.video-panel-block');
      videoPanelBlock.classList.add('closed');
    }
  },
  updated() {

    const showIntroLinks = document.querySelectorAll('.show-intro a');
    const closeIntroLinks = document.querySelectorAll('.close-intro a');
    const videoPanelBlock = document.querySelector('.video-panel-block');
    showIntroLinks.forEach(item => {
      item.addEventListener('click', e => {
        videoPanelBlock.classList.remove('closed');
      })
    });
    closeIntroLinks.forEach(item => {
      item.addEventListener('click', e => {
        videoPanelBlock.classList.add('closed');

      })
    });
if(document.getElementById('video-panel-block'))
window.addEventListener('click', function (e) {
  if (document.getElementById('video-panel-block').contains(e.target)) {

  } else {
    videoPanelBlock.classList.add('closed');
  }
});
  },
  mounted() {

    const showIntroLinks = document.querySelectorAll('.show-intro a');
    const closeIntroLinks = document.querySelectorAll('.close-intro a');
    const videoPanelBlock = document.querySelector('.video-panel-block');
    showIntroLinks.forEach(item => {
      item.addEventListener('click', e => {
        videoPanelBlock.classList.remove('closed');
      })
    });
    closeIntroLinks.forEach(item => {
      item.addEventListener('click', e => {
        videoPanelBlock.classList.add('closed');

      })
    });
if(document.getElementById('video-panel-block'))
window.addEventListener('click', function (e) {
  if (document.getElementById('video-panel-block').contains(e.target)) {

  } else {
    videoPanelBlock.classList.add('closed');
  }
});
  },
} 
</script> 
<style></style>