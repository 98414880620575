<template>
  <headerTmp />
  <VideoTmp />
  <router-view v-slot="{ Component }">
    <transition name="scale-slide">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import headerTmp from "@/components/headerTmp.vue";
import VideoTmp from "@/components/VideoTmp.vue";
export default {
  name: "login",
  components: { headerTmp, VideoTmp },
};
</script>

<style scoped>
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.scale-slide-enter-from {
  top: -10%;
  opacity: 0;
}

.scale-slide-enter-to {
  top: 0%;
  opacity: 1;
}

.scale-slide-leave-from {
  transform: scale(1);
  top: 0;
  opacity: 1;
}

.scale-slide-leave-to {
  transform: scale(0.2);
  opacity: 0;
}
</style>
