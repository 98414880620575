<template>
  <div v-if="$store.getters.getState">
    <header class="header-block">
      <div class="container-fluid">
        <div class="row">
          <div class="logo col-lg-6">
            <router-link to="/dashboard"><img src="@/assets/images/logo.png" alt="Legal Search Box" /></router-link>
          </div>
          <div class="nav col-lg-6">
            <nav id="nav">
              <router-link class="nav-link orders"
                           routerLinkActive="acitve" to="/dashboard" tag="div">
                <span>Dashboard</span>
              </router-link> 
              <router-link class="nav-link add" routerLinkActive="acitve" to="/create" tag="div">
                <span>New Order</span>
              </router-link>
              <router-link class="nav-link summary" to="/cart" tag="div">
                <div class="img-circle" v-if="$store.getters.getBasketMeta.count">{{ $store.getters.getBasketMeta.count }}</div>
                <span v-if="$store.getters.getBasketMeta.count">&euro;{{ $store.getters.getBasketMeta.total.toFixed(2) }}</span>
                <span v-else>€0.00</span>
              </router-link>
              <router-link class="nav-link account" to="/account" tag="div" >
                <span><span class="desktop-text">My </span>Account</span>
                <ul>
                  <li>
                    <router-link to="/account" tag="div">My Account</router-link>
                  </li>
                  <li v-if="getAccount.role == 0 || getAccount.role == 1">
                    <router-link to="/account/company" tag="div">My Company</router-link>
                  </li>
                  <li v-if="getAccount.role == 0 || getAccount.role == 1">
                    <router-link to="/account/user" tag="div">Users</router-link>
                  </li>
                  <li>
                    <router-link to="/logout" tag="a">Logout</router-link>
                  </li>
                </ul>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>

export default {
  name: 'headerTmp',

  computed: {
    getAccount() {
      return this.$store.getters.getAccount;
    },
  },
  mounted() {
    this.nav();
  },
  updated() {
    this.nav();
  },
  methods: {
    nav() {

      if (this.$store.getters.getState) {
        const navLinkAccount = document.querySelector('.nav-link.account');
        const navDropdownAccount = document.querySelector('.nav-link.account ul');
        navLinkAccount.addEventListener('mouseover', (e) => {
          navDropdownAccount.classList.add('active');
        });
        navLinkAccount.addEventListener('mouseout', (e) => {
          navDropdownAccount.classList.remove('active');
        });
        navLinkAccount.addEventListener('click', (e) => {
          if ((e.target.tagName != 'UL') && (e.target.tagName != 'LI')) {
            navDropdownAccount.classList.toggle('active');
          }
          e.stopPropagation();
        });
      }
    }
  }
} 
</script> 
<style>
.header-block .container-fluid {
  max-width: 1500px;
  margin: 0 auto !important;
}
</style>