import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

function clearSession(to, from, next) {
	store.commit("setAccount", false);
	store.commit("setOrders", false);
	store.commit("setOrder", false);
	store.commit("setCorders", false);
	store.commit("setSpent", false);
	store.commit("setPricing", false);
	store.commit("setState", false); 
	next({
		path: "/",
	});
}

function requireAuth(to, from, next) {
	if (!store.getters.getState) {
		store.commit("setAccount", {});
		next({
			path: "/",
			query: {
				redirect: to.fullPath,
			},
		});
	} else {
		return next();
	}
}

function checkAuth(to, from, next) {
	if (!store.getters.getState) {
		return next();
	} else {
		return next({
			path: "/dashboard",
		});
	}
}

let routes = [
	//Auth
	{ path: "/", name: "Login", component: () => import(/* webpackChunkName: "login" */ "../views/auth/login"), beforeEnter: checkAuth },
	{
		path: "/forgot-password",
		name: "Forgot Password",
		component: () => import(/* webpackChunkName: "forgot_password" */ "../views/auth/forgot_password"),
	},
	{
		path: "/reset-password/:code",
		props: true,
		name: "Reset Password",
		component: () => import(/* webpackChunkName: "reset_password" */ "../views/auth/reset_password"),
	},
	{
		path: "/register",
		name: "Register",
		component: () => import(/* webpackChunkName: "register" */ "../views/auth/register"),
	},
	{
		path: "/logout",
		name: "logout",
		beforeEnter: clearSession,
		afterEnter: requireAuth,
	},
	//System
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/system/dashboard"),
		beforeEnter: requireAuth,
	},
	{
		path: "/cart",
		name: "Cart",
		component: () => import(/* webpackChunkName: "cart" */ "../views/system/cart"),
		beforeEnter: requireAuth,
	},
	//Orders
	{
		path: "/create",
		name: "Create ORder",
		component: () => import(/* webpackChunkName: "create_order" */ "../views/orders/create_order"),
		beforeEnter: requireAuth,
	},
	{
		path: "/order/:id",
		name: "Order",
		props: true,
		component: () => import(/* webpackChunkName: "order" */ "../views/orders/view_order"),
		beforeEnter: requireAuth,
	},

	//Account

	{
		path: "/account",
		beforeEnter: requireAuth,
		children: [
			{
				path: "",
				component: () => import(/* webpackChunkName: "account_general" */ "../views/account/general"),
			},
			{
				path: "company",
				component: () => import(/* webpackChunkName: "account_company" */ "../views/account/company"),
			},
			{
				path: "user",
				component: () => import(/* webpackChunkName: "account_settings" */ "../views/account/user"),
			},
		],
	},

	{
		path: "/terms",
		name: "Terms Conditions",
		component: () => import(/* webpackChunkName: "terms" */ "../views/misc/terms"),
	},
	//Misc
	{
		path: "/:pathMatch(.*)",
		name: "Not found",
		component: () => import(/* webpackChunkName: "not_found" */ "../views/misc/not_found"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	mode: "history",
	linkActiveClass: "active",
	linkExactActiveClass: "activee",
	scrollBehavior() {
		return { top: 0, left: 0 };
	},
	routes,
});

export default router;
